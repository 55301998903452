
import { defineComponent } from "vue";
import DxTabPanel from "devextreme-vue/tab-panel";
import mountComponentMixin from "@/mixins/MountComponentMixin";

export default defineComponent({
  components: { DxTabPanel },
  mixins: [mountComponentMixin],
  props: {},
  methods: {
    onSelectTabIndex(value: any) {
      const tab = { ...this.tabs[value] };
      if (!tab.mounted) {
        (this as any).mountComponent(tab.component, tab.domId, {});
        tab.mounted = true;
      }
    },
  },
  watch: {
    selectedTabIndex: function (value: any) {
      this.onSelectTabIndex(value);
    },
  },
  data() {
    return {
      tabsRenderKey: 0,
      selectedTabIndex: 0,
      tabs: [
        {
          id: 0,
          name: "Грузы",
          component:
            "components/catalogues/ImportSettings/PlanCargoImportSettings/PlanCargoImportSettingsCatalogue.vue",
          domId: "PlanCargoImportSettingsId",
          mounted: false,
        },
        {
          id: 1,
          name: "Транспорт",
          component:
            "components/catalogues/ImportSettings/PlanTransportImportSettings/PlanTransportImportSettingsCatalogue.vue",
          domId: "PlanTransportImportSettingsId",
          mounted: false,
        },
      ],
    };
  },
  mounted() {
    this.onSelectTabIndex(this.selectedTabIndex);
  },
  beforeUnmount() {
    this.tabs.forEach((tab: any) => {
      if (tab.mounted) this.unmountComponent(tab.domId);
    });
  },
});
